@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.card-shopping-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 32px;

  .item-info {
    .action-btns {
      gap: 8px;
    }
    .content {
      .item {
        padding: 8px;
        @include singleFixedSize(106px);
      }

      .properties {
        padding: 10px;
        gap: 10px;
        border-radius: 10px;
      }
    }
  }

  .item-info {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .action-btns {
      display: flex;
      align-items: center;
    }

    .content {
      display: flex;
      align-items: center;
      gap: 14px;
      width: 100%;

      .item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--cornerradius12);
        box-shadow: inset 0 0 0 2px #f2f2f2;

        &.item-unavailable {
          opacity: 0.5;
        }

        .item-variant {
          z-index: 1;
          pointer-events: none;
          @include singleFixedSize(48px);

          @include min-1440-break {
            @include singleFixedSize(68px);
          }
        }

        .background {
          position: absolute;
          z-index: 0;
          @include singleFixedSize(56.67px);

          @include min-1440-break {
            @include singleFixedSize(75px);
          }
        }
      }

      .title-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        width: 100%;

        .item-type {
          color: var(--cst-cards-shoping-item-title);
          text-transform: capitalize;
          @include typography-xs;
          @include weight-regular;

          @include min-1440-break {
            @include typography-base;
          }
        }

        .item-title {
          color: var(--cst-cards-shoping-item-subtitle);
          @include typography-base;
          @include weight-semi-bold;

          @include min-1440-break {
            @include typography-l;
          }
        }
      }

      .properties {
        display: flex;
        align-items: center;
        width: max-content;
        background: var(--cst-cards-shoping-item-background-tags);
      }
    }
  }

  height: 120px;
  .item-info {
    gap: 10px;
    .action-btns {
      gap: 6px;
    }
    .content {
      .item {
        padding: 4px;

        @include singleFixedSize(76px);

        @include min-1440-break {
          @include singleFixedSize(106px);
        }
      }

      .properties {
        gap: 6px;
        padding: 6px;
        border-radius: 6px;

        @include min-1440-break {
          gap: 10px;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }

  @include min-1440-break {
    height: 162px;
  }

  .price-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .price {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .current,
      .status {
        color: var(--cst-cards-shoping-item-new-price);

        @include typography-base;
        @include weight-bold;

        @include min-1440-break {
          @include typography-xl;
        }
      }

      .status {
        @include weight-semi-bold;
        @include typography-base;

        @include min-1440-break {
          @include typography-l;
        }
      }

      .old {
        color: var(--cst-cards-shoping-item-old-price);

        @include typography-s;
        @include weight-semi-bold;

        @include min-1440-break {
          @include typography-m;
        }
      }
    }
  }
}
